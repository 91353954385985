import { createAction } from "@reduxjs/toolkit";

const menuEnabled = createAction("MENU_BURGER", (condition) => {
  return {
    payload: condition,
  };
});
const darkMode = createAction("DARK_MODE", (condition) => {
  return {
    payload: condition,
  };
});

export { menuEnabled, darkMode };
