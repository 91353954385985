import React, { useCallback, useState } from "react";
import "../../styles/components/computer/verifyCertificate.scss";
import { Helmet } from "react-helmet";
import axios from "axios";
import Navbar from "../Navbar";
import EditableNavbar from "../EditableNavbar";

const VerifyCertificate = () => {
  const [getCertificateNo, setCertificateNo] = useState("");
  const [certificateData, setCertificateData] = useState([]);
  const [displayMessage, setDisplayMessage] = useState(
    "Validate Your Certificate now."
  );
  const [iconFlag, setIconFalg] = useState("");

  const helperFunctionForState = (
    displayMessageValue,
    iconFlagValue,
    certificateDataValue
  ) => {
    setCertificateData(certificateDataValue);
    setDisplayMessage(displayMessageValue);
    setIconFalg(iconFlagValue);
  };

  const fetchCertificateFromDatabase = useCallback(() => {
    if (getCertificateNo !== "") {
      helperFunctionForState("Validating Your Certificate.....", "", []);
      axios
        .get(
          `https://kheci-api.vercel.app/api/verifyCertificate?certificate=${getCertificateNo}`,
          {
            headers: {
              Authorization: "RKUHPEECSIH",
            },
          }
        )
        .then((res) => {
          if (res?.data?.length > 0) {
            helperFunctionForState(
              "Your Certificate is Valid",
              "check",
              res?.data
            );
          } else {
            helperFunctionForState("Your Certificate is Invalid", "xmark", []);
          }
        })
        .catch((err) => {
          helperFunctionForState(
            "Something went wrong!\nPlease try again",
            "",
            []
          );
          alert("Something went wrong!\nPlease try again");
        });
    } else {
      alert("Please enter certificate number for validate!");
    }
  }, [getCertificateNo]);

  const SplashFooter = ({ bgColor, fColor, footerLogo }) => {
    return (
      <div
        style={{
          width: "100%",
          height: "100px",
          paddingBottom: "20px",
          backgroundColor: bgColor ? bgColor : "#000000",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "50%",
            color: "#004b31",
            fontWeight: "bolder",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-end",
          }}
        >
          from,
        </div>
        <div
          style={{
            width: "100%",
            height: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "bolder",
          }}
        >
          <span style={{ fontSize: "18px", color: fColor ? fColor : "white" }}>
            R-Tech Computer Institution
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className="verifyCertificateContainer">
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="designed & developed by Kalimpong Himalayan Edu-Care Institution"
        />
        <meta name="verify-certificate" content="Verify Computer Certificate" />
        <link rel="icon" href="/favicon.ico" />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css"
          integrity="sha512-xh6O/CkQoPOWDdYTDqeRdPCVd1SpvCA9XXcUnZS2FmJNp1coAFzvtCN9BmamE+4aHK8yyUHUSCcJHgXloTyT2A=="
          crossorigin="anonymous"
          referrerpolicy="no-referrer"
        />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Agbalumo&family=Poppins:wght@500;600;800&display=swap"
          rel="stylesheet"
        />
        <title>Kalimpong Himalayan Edu-Care Institution</title>
      </Helmet>

      <Navbar active="home" />
      <EditableNavbar active="home" />

      <div className="verifyCertificateHeader">Verify Certificate</div>
      <div className="verifyCertificateInput">
        <label htmlFor="certificateInput" className="verifyCertificateTitle">
          Enter Certificate No:{" "}
        </label>
        <input
          id="certificateInput"
          className="certificateInputDesign"
          type="text"
          placeholder="eg: 10XX"
          value={getCertificateNo}
          onChange={(e) => {
            e.preventDefault();
            setCertificateNo(e?.currentTarget?.value);
          }}
        />
        <button className="verifyButton" onClick={fetchCertificateFromDatabase}>
          Submit
        </button>
      </div>
      <div className="verifyIconText">
        {displayMessage}
        <i
          className={`fa-solid fa-circle-${iconFlag}`}
          style={{
            marginLeft: "5px",
            color: iconFlag === "check" ? "green" : "red",
          }}
        ></i>
      </div>
      <div
        className={`container container-sm backgroundVerification ${
          certificateData.length > 0 ? "backgroundVerificationSuccess" : ""
        }`}
      >
        {certificateData.length > 0 ? (
          <div className="verifyCertificateData">
            <table
              className="table table-bordered border-dark"
              style={{
                backgroundColor: "aliceblue",
              }}
            >
              <thead className="table-light">
                <tr
                  className="certificateTableHeaderText"
                  style={{ textAlign: "center" }}
                >
                  <th scope="col">Name</th>
                  <th scope="col">Course</th>
                  <th scope="col">Duration</th>
                  <th scope="col">Year</th>
                </tr>
              </thead>
              <tbody>
                {certificateData?.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      id={index}
                      className="certificateTableDataText"
                      style={{
                        textAlign: "center",
                        border: "1px dashed silver",
                      }}
                    >
                      <td>{item?.NAME}</td>
                      <td>{`${item?.COURSE} (${item?.COURSE_NAME})`}</td>
                      <td>{item?.MONTH}</td>
                      <td>{item?.YEAR}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : null}
      </div>
      <SplashFooter bgColor={"white"} fColor={"black"} />
    </div>
  );
};

export default VerifyCertificate;
