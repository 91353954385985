import "../styles/components/TeamCard.scss";
export default function TeamCard({
  position,
  image,
  name,
  study,
  about,
  email,
  phone,
  icon,
}) {
  return (
    <div
      className="tContainer"
      style={{
        backgroundImage: "url(/background.svg)",
      }}
    >
      <div className="theader">
        <div
          className="theaderInside"
          style={{
            fontFamily: "Poppins sans-serif",
            fontWeight: "lighter",
          }}
        >
          {position}
        </div>
      </div>
      <div className="tImage">
        <div className="tImageHeader">
          <img src={image} alt={`icon${image}`} className="tImageInside" />
        </div>
        <div className="tImageHeaderIcon">
          <i className={icon}></i>
        </div>
      </div>
      <div className="tTitle">
        <span
          style={{
            fontFamily: "Poppins sans-serif",
            fontWeight: "bold",
          }}
        >
          {name}
        </span>
        <span>{study}</span>
      </div>
      <div className="tTitleOther">
        <span
          style={{
            fontSize: "14px",
          }}
        >
          <i className="fa-solid fa-phone me-2"></i>
          {`+91 ${phone}`}
        </span>
        <span
          style={{
            fontSize: "14px",
          }}
        >
          <i className="fa-solid fa-envelope me-2"></i>
          {email}
        </span>
      </div>
      <div className="tExtra">{about}</div>
    </div>
  );
}
