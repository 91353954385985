import "../../styles/components/someBlocksStyle/BlockComputer.scss";

export default function BlockComputer() {
  const sameStyle = {
    display: "block",
    height: "100%",
    width: "100%"
    // maxWidth: "100%",
    // maxHeight: "100%",
  };
  return (
    <div className="blockContainerComputer">
      <div className="blockContainerComputerOne">
        <div
          style={{
            width: "300px",
            backgroundColor: "aliceblue",
            paddingLeft: "10px",
            borderTopRightRadius: "50px",
            fontSize: "30px",
          }}
        >
          Computer Gallery
        </div>
      </div>
      <div className="blockContainerComputerSecond">
        <div className="blockContainerComputerSecondImage">
          <img src="https://kheci.org/compFour.jpg" alt="https://kheci.org/compFour.jpg" style={sameStyle} />
        </div>
        {/*  */}

        {/*  */}
        <div className="blockContainerComputerSecondImage">
          <img
            src="https://kheci.org/R-TECH%20(2).JPG"
            alt="https://kheci.org/R-TECH%20(2).JPG"
            style={sameStyle}
          />
        </div>
        <div className="blockContainerComputerSecondImage">
          <img src="https://kheci.org/compTwo.jpg"
            alt="https://kheci.org/compTwo.jpg" style={sameStyle} />
        </div>
        <div className="blockContainerComputerSecondImage">
          <img src="/R-TECH20(3).JPG" alt="f2" style={sameStyle} />
        </div>
        <div className="blockContainerComputerSecondImage">
          <img
            src="https://kheci.org/newImages/computerThree.jpeg"
            alt="https://kheci.org/newImages/computerThree.jpeg"
            style={sameStyle} />
        </div>
        <div className="blockContainerComputerSecondImage">
          <img
            src="https://kheci.org/newImages/computerTwo.jpeg"
            alt="https://kheci.org/newImages/computerTwo.jpeg"
            style={sameStyle} />
        </div>
      </div>
    </div>
  );
}
