import { menuEnabled, darkMode } from "../actions/index.js";
import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isMenuEnabled: false,
  isDarkMode: false,
};

const actionCombined = createReducer(initialState, (builder) => {
  builder.addCase(menuEnabled, (state, action) => {
    state.isMenuEnabled = action.payload;
  });
  builder.addCase(darkMode, (state, action) => {
    state.isDarkMode = action.payload;
  });
  builder.addDefaultCase((state) => {
    return state;
  });
});

export { actionCombined };
