import App from "../App";
import { createBrowserRouter, RouterProvider, Route } from "react-router-dom";
import ErrorPage from "../components/ErrorPage";
import Blog from "../pages/blog";
import About from "../pages/about";
import Student from "../pages/student";

export const routerDetails = createBrowserRouter([
  {
    id: "home-page",
    path: "/",
    element: <App />,
    // element: <About />,
    errorElement: <ErrorPage />,
  },
  {
    id: "blog-page",
    path: "/blog",
    element: <Blog />,
    errorElement: <ErrorPage />,
  },
  {
    id: "about-page",
    path: "/about",
    element: <About />,
    errorElement: <ErrorPage />,
  },
  {
    id: "student-page",
    path: "/student",
    element: <Student />,
    errorElement: <ErrorPage />,
  },
]);
