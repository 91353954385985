export default function Quotes() {
  return (
    <div
      style={{
        width: "100%",
        height: "400px",
        // backgroundColor: "gainsboro",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#0b292d",
        color: "aliceblue",
        fontFamily: "Poppins,sans-serif",
      }}
    >
      <div
        style={{
          width: "85%",
          height: "150px",
          fontSize: "30px",
          //   backgroundColor: "ivory",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        "We Educate Students Well To Make Their Dream Come True."
      </div>
      <div
        style={{
          width: "100%",
          height: "50px",
          fontSize: "15px",
          //   backgroundColor: "gainsboro",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          paddingRight: "80px",
          fontStyle: "italic",
        }}
      >
        - Rupesh Kumar Singh (Founder)
      </div>
      <div
        onClick={(e) => {
          e.preventDefault();
          alert("Feedback From will be uploaded Soon From, KHECI");
        }}
        style={{
          width: "25px",
          height: "100px",
          backgroundColor: "#eeeeee",
          position: "fixed",
          top: "590px",
          left: "0px",
          zIndex: "10000",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "1px solid silver",
          cursor: "pointer",
          fontFamily: "Poppins, sans-serif",
        }}
      >
        <div
          style={{
            transform: "rotate(270deg)",
            color: "#2f2f2f",
            letterSpacing: "2px",
          }}
        >
          Feedback
        </div>
      </div>
    </div>
  );
}
