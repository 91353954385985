import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Import the FontAwesomeIcon component
import { faEnvelope, faUser } from "@fortawesome/free-solid-svg-icons"; // import the icons you need
import { } from "@fortawesome/free-brands-svg-icons"; // import the icons you need
import "../styles/components/header.scss";

import { customSelector, customDispatch } from "../app/hooks.ts";
import { useSelector, useDispatch } from "react-redux";
export default function Header() {
  const dispatch = useDispatch();
  const { isMenuEnabled, isDarkMode } = customSelector(
    (state) => state.actionCombined
  );
  return (
    <div className="official">
      <div className="officialOne">
        @Official Website of Kalimpong Himalayan Edu-Care Institution
      </div>
      <div className="officialTwo">
        <a
          className="officialTwoTitle"
          href="https://student.kheci.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon
            icon={faUser}
            style={{
              marginRight: 5,
            }}
          />
          Student Login
        </a>

        <a
          className="officialThreeTitle"
          href="mailto:pprincipalkheci@gmail.com"
        // target="_blank"
        // rel="noopener noreferrer"
        >
          <FontAwesomeIcon
            icon={faEnvelope}
            style={{
              marginRight: 5,
            }}
          />
          principalkheci@gmail.com
        </a>
      </div>
    </div>
  );
}
