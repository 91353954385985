import "../../styles/components/someBlocksStyle/BlockForLinkOne.scss";

export default function BlockForLinkOne() {
  const quickLinkOne = {
    // title: "Hall Tickets",
    title: "Quick Link I",
    items: [
      {
        linkTitle: "Hall Ticket",
        link: "https://ignouhall.ignou.ac.in/HallTickets/HALL0620/Hall0620.asp",
      },
      {
        linkTitle: "Class X - XII Hall Ticket",
        link: "https://sdmis.nios.ac.in/search",
      },
      {
        linkTitle: "D.El.Ed Hall Ticket",
        link: "http://dled.nios.ac.in/attendancelogin/examcenter.aspx",
      },
    ],
  };
  const quickLinkTwo = {
    // title: "Result",
    title: "Quick Link II",
    items: [
      {
        linkTitle: "Results",
        link: "https://results.nios.ac.in/home/on-demand?type=2",
      },
      {
        linkTitle: "Class X - XII Result",
        link: "https://results.nios.ac.in/home/on-demand?type=2",
      },
      {
        linkTitle: "Class X - XII Old Result",
        link: "https://results.nios.ac.in/home/on-demand?type=2",
      },
      {
        linkTitle: "B.ED Result",
        link: "http://www.exametc.com/univercity.php?id=511&name=The%20West%20Bengal%20University%20of%20Teachers%20Training%20Education%20Planning%20and%20Administration",
      },
      {
        linkTitle: "Dec 2020 Result",
        link: "http://termendresult.ignou.ac.in/TermEndJune20/TermEndJune20.asp",
      },
    ],
  };
  const quickLinkThree = {
    // title: "Hall Tickets",
    title: "Quick Link III",
    items: [
      {
        linkTitle: "Status",
        link: "http://admission.ignou.ac.in/changeadmdata/admissionstatusnew.asp",
      },
      {
        linkTitle: "Admission Status",
        link: "http://admission.ignou.ac.in/changeadmdata/admissionstatusnew.asp",
      },
      {
        linkTitle: "Asignment Status",
        link: "http://admission.ignou.ac.in/changeadmdata/StatusAssignment.asp",
      },
      {
        linkTitle: "New Admission Status",
        link: "https://ignou.samarth.edu.in/v/verify/student",
      },
    ],
  };
  const quickLinkFour = {
    // title: "Result",
    title: "Quick Link IV",
    items: [
      {
        linkTitle: "X & XII Online Exam Fee",
        link: "https://sdmis.nios.ac.in/registration/exam",
      },
      {
        linkTitle: "Computer Admission Fee",
        link: "http://www.kheci.org/COURSE%20FEE%202018.pdf",
      },
      {
        linkTitle: "Computer Form",
        link: "http://www.kheci.org/computeradmform.pdf",
      },
      {
        linkTitle: "SC, ST and OBC Online Form",
        link: "#",
      },
      {
        linkTitle: "Passport Online Apply",
        link: "https://portal2.passportindia.gov.in/AppOnlineProject/welcomeLink",
      },
      {
        linkTitle: "Pan Online Apply",
        link: "https://tin.tin.nsdl.com/pan/newpanforeign.html",
      },
    ],
  };

  const ListItem = ({ title, arrayOfItems }) => {
    return (
      <div
        class="card"
        style={{
          backgroundImage: "url(/backSVG.png)",
        }}
      >
        <div class="card-body">
          <h5 class="card-title">{title}</h5>
        </div>
        <ul class="list-group-flush blockForLinkOneListContainerListItems">
          {arrayOfItems?.map((item, index) => (
            <li class="list-group-item">
              →
              <a href={item.link} target="_blank" rel="noreferrer noopener">
                {item.linkTitle}
              </a>
            </li>
          ))}
        </ul>
        <div class="card-footer">
          <small class="text-muted">
            Kalimpong Himalayan Edu-Care Institution
          </small>
        </div>
      </div>
    );
  };
  return (
    <div className="blockForLinkOneContainer ps-5 pe-5">
      <ListItem title={quickLinkOne.title} arrayOfItems={quickLinkOne.items} />
      <ListItem title={quickLinkTwo.title} arrayOfItems={quickLinkTwo.items} />
      <ListItem
        title={quickLinkThree.title}
        arrayOfItems={quickLinkThree.items}
      />
      <ListItem
        title={quickLinkFour.title}
        arrayOfItems={quickLinkFour.items}
      />
    </div>
  );
}
