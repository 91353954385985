import { useCallback, useEffect, useState } from "react";
import "../../styles/components/someBlocksStyle/BlockThird.scss";
import { getCustomYear } from "@hirishu10/simple-date-time";

export default function BlockThird() {
  const [getYear, setYear] = useState("");

  const yearCallback = useCallback(
    (y) => {
      setYear(y);
    },
    [getYear]
  );
  return (
    <div className="blockContainerThree">
      <div className="blockContainerThreeOne">
        <img
          src="https://kheci.org/kheciadm.png"
          alt="admission"
          style={{
            display: "block",
            maxWidth: "100%",
          }}
        />
        <div className="blockContainerThreeOneTextContainer">
          {`B.ED/D.EL.ED ${getCustomYear("yyyy")}-${
            Number(getCustomYear("yy")) + 2
          }`}
        </div>
        <div
          style={
            {
              // backgroundColor: "red",
            }
          }
        >
          <ul className="blockContainerThreeOneItems">
            <li>
              <img
                src="/newarrow.gif"
                alt=""
                width="50px"
                height="50px"
                style={{
                  marginRight: "5px",
                }}
              />
              <a
                href="https://kheci-api.vercel.app/requestform"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  fontSize: "20px",
                  backgroundColor: "#b0b0b021",
                  padding: "10px",
                  boxShadow: "0px 0px 8px 1px #dbdbdb",
                }}
              >
                Book Your Seats
              </a>
            </li>
            <li>
              →
              <a
                href="https://www.wbuttepa.ac.in/"
                target="_blank"
                rel="noopener noreferrer"
              >
                B.ED Results
              </a>
            </li>
            <div
              style={{
                // backgroundColor: "red",
                marginTop: "10px",
              }}
            >
              <span>B.Ed Student List</span>
              <select
                style={{
                  fontSize: "16px",
                  textAlign: "center",
                  cursor: "pointer",
                  marginLeft: "10px",
                  padding: "5px",
                  border: "1px solid silver",
                }}
                onChange={(e) => {
                  e.preventDefault();
                  yearCallback(e.currentTarget.value);
                }}
              >
                <option value={""}>Choose Year</option>
                <option value={"2016"}>2016</option>
                <option value={"2017"}>2017</option>
                <option value={"2018"}>2018</option>
                <option value={"2019"}>2019</option>
                <option value={"2020"}>2020</option>
                <option value={"2021"}>2021</option>
                <option value={"2022"}>2022</option>
                <option value={"2023"}>2023</option>
              </select>
            </div>
            <div
              style={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // backgroundColor: "red",
                transition: "0.3s",
              }}
            >
              {getYear !== "" ? (
                <a
                  style={{
                    marginLeft: "20px",
                    backgroundColor: "black",
                    color: "white",
                    textDecoration: "none",
                    padding: "5px 15px",
                    transition: "0.3s",
                  }}
                  href={`https://kheci.org/bed_list/${getYear}.pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View
                </a>
              ) : null}
            </div>
          </ul>
        </div>
        {/*  */}
        <div
          style={{
            width: "100%",
            height: "100px",
            // backgroundColor: "dodgerblue",
          }}
        >
          <span
            style={{
              width: "100%",
              height: "50%",
              // backgroundColor: "red",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            + Free Study Materials
          </span>
          <span
            style={{
              width: "100%",
              height: "50%",
              // backgroundColor: "red",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              fontSize: "12px",
            }}
          >
            * Terms and Condition apply
          </span>
        </div>
      </div>
      <div className="blockContainerThreeTwo">
        <img
          src="/fback.jpg"
          alt="fback"
          style={{
            display: "block",
            maxWidth: "100%",
          }}
        />
      </div>
    </div>
  );
}
