import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "./styles/home/home.scss";

import Header from "./components/Header.jsx";
import Navbar from "./components/Navbar.jsx";
import Carousel from "./components/Carousel.jsx";

import "./styles/globals.css";
import "bootstrap/dist/css/bootstrap.min.css"; // Import bootstrap CSS

import "@fortawesome/fontawesome-svg-core/styles.css"; // import Font Awesome CSS
import { config } from "@fortawesome/fontawesome-svg-core";
import Footer from "./components/Footer";
import NoticeBlock from "./components/NoticeBlock";
import Quotes from "./components/Quotes.jsx"; // Tell Font Awesome to skip adding the CSS automatically since it's being imported above
import BlockThird from "./components/someBlocks/BlockThird";
import BlockFifth from "./components/someBlocks/BlockFifth";
import BlockForLinkOne from "./components/someBlocks/BlockForLinkOne";
import BlockImagesOne from "./components/someBlocks/BlockImagesOne";
import BlockForLinkTwo from "./components/someBlocks/BlockForLinkTwo";
import BlockComputer from "./components/someBlocks/BlockComputer";
import BlockComputerMain from "./components/someBlocks/BlockComputerMain";
import About from "./pages/about";
import EditableNavbar from "./components/EditableNavbar";

config.autoAddCss = false;
export default function App() {
  useEffect(() => {
    require("bootstrap/dist/js/bootstrap.bundle.min.js");
  }, []);

  return (
    <div className="homeContainer">
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="designed & developed by Kalimpong Himalayan Edu-Care Institution"
        />
        <link rel="icon" href="/favicon.ico" />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css"
          integrity="sha512-xh6O/CkQoPOWDdYTDqeRdPCVd1SpvCA9XXcUnZS2FmJNp1coAFzvtCN9BmamE+4aHK8yyUHUSCcJHgXloTyT2A=="
          crossorigin="anonymous"
          referrerpolicy="no-referrer"
        />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Agbalumo&family=Poppins:wght@500;600&display=swap"
          rel="stylesheet"
        />
        <title>Kalimpong Himalayan Edu-Care Institution</title>
      </Helmet>

      <Header />
      <Navbar active="home" />
      <EditableNavbar active="home" />
      <Carousel />
      <div className="newNursingConatiner">
        <div className="newNursingBlockOne">
          <a href="https://kheci-api.vercel.app/nursingRequestForm" target="_blank"
            rel="noopener noreferrer">
            <img
              src="/new_nursing_ban_two.jpg"
              style={{
                width: "100%",
                height: "100%",
              }}
              alt="GNM_Kheci_Banner2"
            />
          </a>
        </div>
        <div className="newNursingBlockTwo">
          <a href="https://kheci-api.vercel.app/nursingRequestForm" target="_blank"
            rel="noopener noreferrer">
            <img
              src="/new_nursing_ban_three.jpg"
              style={{
                width: "100%",
                height: "100%",
              }}
              alt="GNM_Kheci_Banner3"
            />
          </a>
        </div>
      </div>
      <div
        style={{
          // backgroundColor: "red",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "auto",
          borderBottom: "1px solid silver",
        }}
      >
        <a href="https://kheci.org/">
          <img
            src="/new_branding.png"
            style={{
              width: "100%",
              height: "100%",
            }}
            alt="Student Console"
          />
        </a>
      </div>
      <Quotes />
      <div
        style={{
          // backgroundColor: "red",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "auto",
          borderBottom: "1px solid silver",
        }}
      >
        <a href="https://student.kheci.org/" target="_blank" rel="noreferrer">
          <img
            src="/student_console.png"
            style={{
              width: "100%",
              height: "100%",
            }}
            alt="Student Console"
          />
        </a>
      </div>

      <BlockThird />
      <BlockFifth />

      <BlockForLinkOne />
      <div
        style={{
          // backgroundColor: "red",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "auto",
        }}
      >
        <img
          src="https://kheci.org/faculty_member/rtech_flex.jpeg"
          style={{
            width: "70%",
            height: "100%",
          }}
          alt="R-Tech Computer Institution"
        />
      </div>
      <BlockImagesOne />

      <NoticeBlock />
      <BlockComputerMain />
      <BlockComputer />
      <Footer />

      {/* For Management Page */}
      {/* <About /> */}
    </div>
  );
}
