import "../styles/components/Notice.scss";
import SomeCard from "./SomeCard";
import { downloadItems, resultItems } from "../assets/data/index.js";

export default function NoticeBlock() {
  return (
    <div className="noticeContainer">
      <div className="noticeContainerFirst">
        <div className="noticeContainerFirstOne">
          <SomeCard
            title={downloadItems?.title}
            icon={downloadItems?.icon}
            items={downloadItems?.items}
            iconBanner={downloadItems?.iconBanner}
          />
          <SomeCard
            title={resultItems?.title}
            icon={resultItems?.icon}
            items={resultItems?.items}
            iconBanner={resultItems?.iconBanner}
          />
        </div>
        <div className="noticeContainerFirstSecond">
          <img src="/fPhoto.jpg" alt="admission" width="50%" height="90%" />
          <img
            src="https://kheci.org/khecibed1.jpg"
            alt="admission"
            width="50%"
            height="90%"
            style={{
              marginLeft: "10px ",
            }}
          />
        </div>
      </div>
      <div className="noticeContainerSecond">
        <div className="noticeTitle">
          <i className="fa-solid fa-newspaper me-2"></i>Notice and Advertisment
        </div>
        <div className="noticeBody">
          <div className="noticeBodyOne">
            <marquee
              style={{
                backgroundColor: "#c9cccc",
                color: "red",
              }}
            >
              All Latest news and advertisment will be displayed here
            </marquee>
            <ul
              style={{
                paddingTop: "10px",
                paddingBottom: "10px",
                listStyle: "square",
              }}
            >
              <li>
                <img
                  src="/newarrow.gif"
                  alt=""
                  width="20px"
                  height="20px"
                  style={{
                    marginRight: "5px",
                  }}
                />
                <a
                  href="https://dled.nios.ac.in/teacherlogin.aspx"
                  style={{
                    textDecoration: "none",
                  }}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Admission Going on X,XII B.A, MA, B.ED, B.PED, etc.
                </a>
              </li>
            </ul>
          </div>
          {/*  */}
          <div className="noticeBodyTwo">
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSeLGl7NZ9y9IVToDT205NSKHsUmWYVGDE8R1osN0DQ-0x-d1g/viewform"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                src="/newAdmissionOpen.gif"
                alt="R-Tech Computer Institute"
                style={{
                  display: "block",
                  maxWidth: "100%",
                }}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
