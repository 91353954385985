// :: Redux ToolKit
import { configureStore } from "@reduxjs/toolkit";
import { actionCombined } from "./redux/reducers/actionCombined";

const store = configureStore({
  reducer: {
    actionCombined,
  },
  // devTools: process.env.NODE_ENV !== "production",
  devTools: false,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
