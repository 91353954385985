import "../styles/student/index.scss";
import axios from "axios";

export default function Student() {
  // const [getRollNo, setRollNo] = useSta();

  axios
    .get("https://kheci-api.vercel.app/api/test?roll='22A01-0007'", {
      headers: {
        Authorization: "RKUHPEECSIH",
      },
    })
    .then((res) => {
      console.log("res", res);
    })
    .catch((err) => {
      console.log("err", err);
    });

  return (
    <div
      className="studentContainer"
      style={{
        backgroundImage: "url(/oneTest.jpg)",
      }}
    >
      <div className="studentHeader">
        <div className="studentHeaderOne">
          <img src="/test.svg" alt="test" />
        </div>
        <div className="studentHeaderTwo">
          <ul className="studentHeaderNav">
            <li>
              <a href="">Something</a>
            </li>
            <li>
              <a href="">Something</a>
            </li>
          </ul>
        </div>
      </div>
      {/*  */}
      {/*  */}
      <div className="searchContainer">
        <form className="searchContainerBox" method="GET">
          <div className="searchContainerOne">
            <span
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label htmlFor="roll_no">Roll No:</label>
              <input id="roll_no" type="text" placeholder="Roll No" />
            </span>
            {/*  */}
            <span
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label htmlFor="name">Name:</label>
              <input id="name" type="text" placeholder="Name" />
            </span>
          </div>
          {/* <div className="searchContainerTwo">
            <span
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label htmlFor="fName">Father's Name:</label>
              <input id="fName" type="text" placeholder="Father's Name" />
            </span>
            <span
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label htmlFor="mName">Mother's Name:</label>
              <input id="mName" type="text" placeholder="Mother's Name" />
            </span>
          </div> */}
          <div className="searchContainerThree">
            {/* <span
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label htmlFor="cName">Course:</label>
              <input id="cName" type="text" placeholder="Course" />
            </span> */}
            <span
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <label htmlFor="aadhar">Aadhar No:</label>
              <input id="aadhar" type="text" placeholder="Aadhar No" />
            </span>
          </div>
          <div className="searchContainerFour">
            <input
              type="reset"
              value="Reset"
              style={{
                marginRight: "20px",
              }}
            />
            <input type="submit" value="Search" />
          </div>
          {/*  */}
        </form>
      </div>
      <div className="queryContainer">
        Please use above search box for Student Details!
      </div>
    </div>
  );
}
// "ROLL NO": "22A01-1823",
// "NAME": "RITA SUBBA",
// "COURSE": "CCA",
// "FATHER'S NAME": "MANGAL SUBBA ",
// "MOTHER'S NAME": "SABITA SUBBA",
// "ADHAR NO": "843512573299",
