import "../styles/components/Footer.scss";
import {
  getCustomDayNameFull,
  getCustomMonthNameShort,
  getCustomDate,
  getCustomDTFormatter,
  getCustomYear,
} from "@hirishu10/simple-date-time";

import { useEffect, useState } from "react";

export default function Footer() {
  const [clock, setClock] = useState("h:m:s");

  const fontSizeOne = {
    fontSize: "25px",
  };
  const fontSizeTwo = {
    fontSize: "20x",
  };
  const fontSizeThree = {
    fontSize: "15px",
  };

  const systemConfigOk = [
    {
      title: "Some Error",
      color: "red",
    },
    {
      title: "All systems normal.",
      color: "blue",
    },
  ];

  useEffect(() => {
    const timeForInterval = setInterval(() => {
      setClock(
        getCustomDTFormatter().format("h m s ap", {
          h: " : ",
          m: " : ",
        })
      );
    }, 1000);

    return () => {
      clearInterval(timeForInterval);
    };
  }, [clock]);

  return (
    <div className="footerContainer">
      <div className="footerContainerOne">
        <div className="footerFirst">
          <span style={fontSizeOne}>
            Kalimpong Himalayan Edu-Care Institution
          </span>
          <span style={fontSizeTwo}>Above Ganesh Mandir, Hatt Bazar,</span>
          <span style={fontSizeTwo}>Kalimpong, West Bengal, 734301</span>
          <span style={fontSizeThree} className="mb-1">
            <a href="tel:+919832368234" style={{ color: "white", textDecoration: "none" }}>
              <i className="fa-solid fa-phone"></i> +91 9832368234, +91 9933025292
            </a>
          </span>
          <span style={fontSizeThree} className="mb-1">
            <a href="tel:03552 255444" style={{ color: "white", textDecoration: "none" }}>
              <i className="fa-solid fa-tty fw-bold"></i> 03552 255444
            </a>
          </span>
          <span style={fontSizeThree} className="mb-1">
            <a href="tel:+919832368234" style={{ color: "white", textDecoration: "none" }}>
              <i className="fa-brands fa-whatsapp fw-bold"></i> +91 9832368234
            </a>
          </span>
          <span style={fontSizeThree} className="mb-1">
            <a href="mailto:principalkheci@gmail.com" style={{ color: "white", textDecoration: "none" }}>
              <i className="fa-solid fa-envelope"></i> principalkheci@gmail.com
            </a>
          </span>
          <span style={fontSizeThree} className="mb-1">
            <a
              href="https://kheci.org/"
              style={{ textDecoration: "none", color: "white" }}
            >
              <i className="fa-sharp fa-solid fa-arrow-up-right-from-square me-1"></i>
            </a>
            https://kheci.org/
          </span>
          {/*  */}
          {/*  */}
          <div
            className="footerNote"
            style={{
              width: "100%",
              height: "50px",
              marginTop: "90px",
              fontSize: "13px",
            }}
          >
            {`Note: This website is under licensed. If you need any query please reach out to admin Mr.Rupesh Kumar Singh (Co-Founder).`}
          </div>
        </div>
        {/*  */}
        <div className="footerSecond">
          <ul className="footerItems">
            <li>
              →<a href="https://kheci-api.vercel.app/admin">Admin</a>
            </li>
            <li>
              →<a href="/carrer">Carrers</a>
            </li>
            <li>
              →
              <a href="https://webservices.ignou.ac.in/assignments/">
                Downloads
              </a>
            </li>
            <li>
              →
              <a href="https://results.nios.ac.in/home/on-demand?type=2">
                Admission
              </a>
            </li>
            <li>
              →
              <a href="https://results.nios.ac.in/home/on-demand?type=2">
                Results
              </a>
            </li>
          </ul>
        </div>
        <div className="footerThird">
          <ul className="footerItems">
            <li>
              →<a href="/blog">Blog</a>
            </li>
            <li>
              →<a href="https://kheci.org/gallary.html">Gallery</a>
            </li>
            <li>
              →<a href="https://kheci.org/pride.html">Distance</a>
            </li>
            <li>
              →<a href="https://kheci.org/rtech_student.html">Computer</a>
            </li>
            <li>
              →
              <a href="https://pages.razorpay.com/pl_FtT68J7GL63XPe/view">
                Fee Payment
              </a>
            </li>
          </ul>
        </div>
        {/*  */}
        <div className="footerFourth">
          <div className="footerFourthFirst">
            <span>
              <a href="facebook">
                <i className="fa-brands fa-facebook-f"></i>
              </a>
            </span>
            <span>
              <a href="insta">
                <i className="fa-brands fa-square-instagram"></i>
              </a>
            </span>
            <span>
              <a href="twitter">
                <i className="fa-brands fa-twitter"></i>
              </a>
            </span>
            <span>
              <a href="linkedin">
                <i className="fa-brands fa-linkedin"></i>
              </a>
            </span>
            <span>
              <a href="github">
                <i className="fa-brands fa-github"></i>
              </a>
            </span>
            <span>
              <a href="maps">
                <i className="fa-solid fa-map"></i>
              </a>
            </span>
          </div>
          {/*  */}
          <div className="footerFourthSecond">
            <div className="something">
              <div className="something-1">
                <p id="setDate">
                  {`${getCustomDate()} ${getCustomMonthNameShort(
                    "p"
                  )} ${getCustomYear("yyyy")} - ${getCustomDayNameFull("p")} `}
                </p>
              </div>
              <div className="something-2">
                <p id="setTime">
                  {clock}
                  <img
                    src="/india.svg"
                    alt="india"
                    width="25px"
                    height="25px"
                  />
                </p>
              </div>
            </div>
          </div>
          {/*  */}
          <div className="footerFourthThird">
            <div
              style={{
                width: "250px",
                height: "35px",
                backgroundColor: "#01363c",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "5px",
              }}
            >
              Status:
              <span
                className="circleStyle"
                style={{ backgroundColor: systemConfigOk[1].color }}
              ></span>
              {systemConfigOk[1].title}
            </div>
          </div>
          <div className="footerFourthFourth">Version:1.2.0</div>
          <div className="footerFourthFifth">
            Designed & Developed by K.H.E.C.I
          </div>
        </div>
      </div>
      <div className="footerContainerTwo">
        {` Copyright © ${getCustomYear(
          "yyyy"
        )} K.H.E.C.I. All rights reserved.`}
      </div>
    </div >
  );
}
