import "bootstrap/dist/css/bootstrap.css";

export default function Carousel() {
  return (
    <div
      id="carouselExampleFade"
      className="carousel slide carousel-fade w-100"
      data-bs-ride="carousel-item"
    >
      <div className="carousel-inner">
        <div className="carousel-item active">
          <img
            src="https://kheci.org/newImages/banner/bannerFour.jpeg"
            alt="https://kheci.org/newImages/banner/bannerFour.jpeg"
            style={{
              display: "block",
              width: "100%",
              height: "auto",
            }}
          />
        </div>
        <div className="carousel-item">
          <img
            src="https://kheci.org/newImages/banner/bannerThree.jpeg"
            alt="https://kheci.org/newImages/banner/bannerThree.jpeg"
            style={{
              display: "block",
              width: "100%",
              height: "auto",
            }}
          />
        </div>
        <div className="carousel-item">
          <img
            src="/main_banner.jpg"
            alt="main_banner"
            style={{
              display: "block",
              width: "100%",
              height: "auto",
            }}
          />
        </div>
        <div className="carousel-item">
          <img
            src="/R-TECH20(4).JPG"
            alt="main_bannerTwo"
            style={{
              display: "block",
              width: "100%",
              height: "auto",
            }}
          />
        </div>
        <div className="carousel-item">
          <img
            src="/one.gif"
            alt="one"
            style={{
              display: "block",
              width: "100%",
              height: "auto",
            }}
          />
        </div>
        <div className="carousel-item">
          <img src="/fback.jpg" className="d-block w-100" alt="backImg" />
        </div>
        <div className="carousel-item">
          <img
            src="https://kheci.org/newImages/computerSix.jpeg"
            alt="https://kheci.org/newImages/computerSix.jpeg"
            className="d-block w-100"
          />
        </div>
        <div className="carousel-item">
          <img
            src="https://kheci.org/newImages/computerFour.jpeg"
            alt="https://kheci.org/newImages/computerFour.jpeg"
            className="d-block w-100"
          />
        </div>
        <div className="carousel-item">
          <img
            src="https://kheci.org/images/demo/featured-slide/IMG2.gif"
            className="d-block w-100"
            alt="imgThree"
          />
        </div>
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleFade"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleFade"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
}
