import "../../styles/components/someBlocksStyle/BlockImagesOne.scss";

export default function BlockImagesOne() {
  return (
    <div className="blockImagesOneContainer">
      <div className="blockImagesOneContainerOne">
        <img
          src="https://kheci.org/images/demo/KHECIII.jpeg"
          alt="https://kheci.org/images/demo/KHECIII.jpeg"
          style={{
            display: "block",
            maxWidth: "100%",
            boxShadow: "0px 0px 6px 1px #7c7c7c",
          }}
        />
      </div>
      <div className="blockImagesOneContainerTwo">
        <img
          src="https://kheci.org/R-TECH20(4).JPG"
          alt="https://kheci.org/R-TECH20(4).JPG"
          style={{
            display: "block",
            maxWidth: "100%",
            boxShadow: "0px 0px 6px 1px #7c7c7c",
          }}
        />
        <img
          src="https://kheci.org/newImages/banner/bannerOne.jpeg"
          alt="https://kheci.org/newImages/banner/bannerOne.jpeg"
          style={{
            display: "block",
            maxWidth: "100%",
            boxShadow: "0px 0px 6px 1px #7c7c7c",
          }}
        />
      </div>
    </div>
  );
}
