import "../styles/components/navbar.scss";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";

import {} from "@fortawesome/react-fontawesome"; // Import the FontAwesomeIcon component
import {} from "@fortawesome/free-solid-svg-icons"; // import the icons you need
import {} from "@fortawesome/free-brands-svg-icons"; // import the icons you need
import { useEffect, useState } from "react";

import { customSelector, customDispatch } from "../app/hooks.ts";
import { useSelector, useDispatch } from "react-redux";
import { menuEnabled, darkMode } from "../redux/actions/index.js";

import "animate.css";
import $ from "jquery";

export default function Navbar({ active }) {
  const dispatch = useDispatch();
  const { isMenuEnabled, isDarkMode } = customSelector(
    (state) => state.actionCombined
  );

  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    // console.log("window.innerWidth", window.innerWidth);

    if (innerWidth === 1188) {
      dispatch(menuEnabled(false));
    } else {
      setInnerWidth(window.innerWidth);
    }
    $("#navbarResponsive").addClass("animate__animated animate__bounceInRight");
    $(`#darkLightButton`).addClass("animate__animated animate__bounceIn");
    setTimeout(() => {
      $(`#darkLightButton`).removeClass("animate__animated animate__bounceIn");
    }, [800]);
  }, [dispatch, innerWidth, isMenuEnabled, isDarkMode]);

  return (
    <div className="navbarContainer">
      {/*  */}
      {isMenuEnabled && isMenuEnabled ? (
        <div
          id="navbarResponsive"
          style={{
            width: "100%",
            height: "800px",
            position: "absolute",
            backgroundColor: "#0a2225",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span
            style={{
              width: "100%",
              height: "50px",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              paddingRight: "20px",
              position: "relative",
            }}
          >
            <a
              onClick={(e) => {
                dispatch(menuEnabled(!isMenuEnabled));
              }}
              style={{
                display: "flex",
                textDecoration: "none",
                cursor: "pointer",
                color: "white",
              }}
            >
              <i className="fa-solid fa-xmark" style={{ fontSize: "30px" }}></i>
            </a>
          </span>
          <div>
            <ul
              style={{
                listStyle: "none",
                display: "inline-block",
                padding: "50px",
              }}
              className="navbarItems"
            >
              <li
                className="navbarItemsList"
                style={{
                  padding: "10px",
                }}
              >
                →
                <a
                  style={{ color: active === "home" ? "#ffffff" : "none" }}
                  href="https://kheci.org/"
                >
                  Home
                </a>
              </li>
              <li
                className="navbarItemsList"
                style={{
                  padding: "10px",
                }}
              >
                →{" "}
                <a
                  href="http://management.kheci.org/"
                  style={{
                    color: active === "management" ? "#ffffff" : "none",
                  }}
                >
                  Management
                </a>
              </li>
              {/* Computer */}
              <li
                className="nav-item dropdown d-flex"
                style={{
                  padding: "10px",
                }}
              >
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{
                    marginLeft: "5px",
                  }}
                >
                  Computer
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a
                      className="dropdown-item"
                      href="https://kheci.org/rtech_student.html"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {"Computer Details"}
                    </a>
                  </li>

                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="https://student.kheci.org/"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {/* <img
                        src="/newarrow.gif"
                        alt=""
                        width="20px"
                        height="20px"
                        style={{ marginRight: "5px" }}
                      /> */}
                      Student Login
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="/computer/verify"
                      // target="_blank"
                      // rel="noreferrer noopener"
                    >
                      <img
                        src="/newarrow.gif"
                        alt=""
                        width="20px"
                        height="20px"
                        style={{ marginRight: "5px" }}
                      />
                      Verify Certificate
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item disabled" href="#">
                      Updated Soon
                    </a>
                  </li>
                </ul>
              </li>
              {/* Courses */}
              <li
                className="nav-item dropdown d-flex"
                style={{
                  padding: "10px",
                }}
              >
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{
                    marginLeft: "5px",
                  }}
                >
                  Courses
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="#">
                      className - X
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      className - XII
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      {"B.A (Bachelor's Of Arts)"}
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      {"M.A (Master's Of Arts)"}
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Others
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      {"C.C.A (Certificate in Computer Application)"}
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      {"D.C.A (Diploma in Computer Application)"}
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      {"A.D.C.A (Advance Diploma in Computer Application)"}
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      B.ED/D.EL.ED
                    </a>
                  </li>
                </ul>
              </li>
              {/*  */}
              {/* Downloads */}
              <li
                className="nav-item dropdown d-flex"
                style={{
                  padding: "10px",
                }}
              >
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{
                    marginLeft: "5px",
                  }}
                >
                  Downloads
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a
                      className="dropdown-item"
                      href="https://webservices.ignou.ac.in/assignments/"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {"ASSIGNMENT (BA, MA)"}
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="https://www.nios.ac.in/student-information-section/tutor-mark-assignment-status.aspx"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {"ASSIGNMENT (X, XII)"}
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="https://ignouhall.ignou.ac.in/HallTickets/HALL0620/Hall0620.asp"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {"HALL TICKET"}
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                    <span style={{ marginLeft: "5px" }}>BOSSE</span>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="https://kheci-api.vercel.app/bose/admit"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <img
                        src="/newarrow.gif"
                        alt=""
                        width="20px"
                        height="20px"
                        style={{ marginRight: "5px" }}
                      />
                      {"BOSSE Admit"}
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="https://kheci-api.vercel.app/bose/marksheet"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <img
                        src="/newarrow.gif"
                        alt=""
                        width="20px"
                        height="20px"
                        style={{ marginRight: "5px" }}
                      />
                      {"BOSSE Marksheet"}
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="https://kheci-api.vercel.app/bose/migration"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <img
                        src="/newarrow.gif"
                        alt=""
                        width="20px"
                        height="20px"
                        style={{ marginRight: "5px" }}
                      />
                      {"BOSSE Migration"}
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                    <span style={{ marginLeft: "5px" }}>B.Ed</span>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="https://kheci-api.vercel.app/bedDegree"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <img
                        src="/newarrow.gif"
                        alt=""
                        width="20px"
                        height="20px"
                        style={{ marginRight: "5px" }}
                      />
                      {"B.Ed Degree"}
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="https://kheci-api.vercel.app/bedMarksheet"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <img
                        src="/newarrow.gif"
                        alt=""
                        width="20px"
                        height="20px"
                        style={{ marginRight: "5px" }}
                      />
                      {"B.Ed Marksheet"}
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="https://kheci-api.vercel.app/bedCertificate"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <img
                        src="/newarrow.gif"
                        alt=""
                        width="20px"
                        height="20px"
                        style={{ marginRight: "5px" }}
                      />
                      {"B.Ed Certificate"}
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                    <span style={{ marginLeft: "5px" }}>D.El.Ed</span>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="https://kheci-api.vercel.app/deledRegistration"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <img
                        src="/newarrow.gif"
                        alt=""
                        width="20px"
                        height="20px"
                        style={{ marginRight: "5px" }}
                      />
                      {"D.El.Ed Registration"}
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="https://kheci-api.vercel.app/deledCertificate"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <img
                        src="/newarrow.gif"
                        alt=""
                        width="20px"
                        height="20px"
                        style={{ marginRight: "5px" }}
                      />
                      {"D.El.Ed Certificate"}
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="https://kheci-api.vercel.app/deledMarksheet"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <img
                        src="/newarrow.gif"
                        alt=""
                        width="20px"
                        height="20px"
                        style={{ marginRight: "5px" }}
                      />
                      {"D.El.Ed Marksheet"}
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <a className="dropdown-item disabled" href="#">
                      Updated Soon
                    </a>
                  </li>
                </ul>
              </li>

              {/* Results */}
              <li
                className="nav-item dropdown d-flex"
                style={{
                  padding: "10px",
                }}
              >
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{
                    marginLeft: "5px",
                  }}
                >
                  Results
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a
                      className="dropdown-item"
                      href="http://ignou.ac.in/ignou/studentzone/results/1"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {"RESULT (BA, MA)"}
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="https://results.nios.ac.in/"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {"RESULT (X, XII)"}
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <a className="dropdown-item disabled" href="#">
                      Updated Soon
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      ) : null}

      {/*  */}
      <div className="navbarLogo">
        <div className="navbarLogoTwo">
          <div className="navbarLogoTwoTitle">
            Kalimpong Himalayan Edu-Care Institution
          </div>
          <div
            style={{
              fontSize: "12px",
              // color: isDarkMode ? "#ececec" : "#878787",
            }}
          >
            K H E C I
          </div>
        </div>
      </div>

      <div className="navbarTitle">
        <a
          className="iconHamburger"
          onClick={(e) => {
            // alert(menuEnabled);
            dispatch(menuEnabled(!isMenuEnabled));
            // setMenuOpen(!menuOpen);
          }}
        >
          <i className="fa-solid fa-bars"></i>
        </a>
        {/*  */}
        <ul className="navList">
          <li
            className="navListItem itemHover"
            style={{ backgroundColor: active === "home" ? "#1a3c41" : "none" }}
          >
            <a
              className="navListItemA"
              style={{ color: active === "home" ? "#ffffff" : "none" }}
              href="https://kheci.org/"
            >
              Home
            </a>
          </li>
          {/* Management */}
          <li
            className="navListItem itemHover"
            style={{
              backgroundColor: active === "management" ? "#1a3c41" : "none",
            }}
          >
            <a
              className="navListItemA"
              href="http://management.kheci.org/"
              style={{ color: active === "management" ? "#ffffff" : "none" }}
            >
              Management
            </a>
          </li>
          {/* Computer */}
          <li
            className="nav-item dropdown d-flex"
            style={{
              padding: "10px",
            }}
          >
            <a
              className="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style={{
                marginLeft: "5px",
              }}
            >
              Computer
            </a>
            <ul className="dropdown-menu">
              <li>
                <a
                  className="dropdown-item"
                  href="https://kheci.org/rtech_student.html"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {"Computer Details"}
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  href="https://student.kheci.org/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {"Student Details"}
                </a>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <a
                  className="dropdown-item"
                  href="https://student.kheci.org/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {/* <img
                    src="/newarrow.gif"
                    alt=""
                    width="20px"
                    height="20px"
                    style={{ marginRight: "5px" }}
                  /> */}
                  Student Login
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  href="/computer/verify"
                  // target="_blank"
                  // rel="noreferrer noopener"
                >
                  <img
                    src="/newarrow.gif"
                    alt=""
                    width="20px"
                    height="20px"
                    style={{ marginRight: "5px" }}
                  />
                  Verify Certificate
                </a>
              </li>
              <li>
                <a className="dropdown-item disabled" href="#">
                  Updated Soon
                </a>
              </li>
              {/* <li>
                <a class="dropdown-item disabled" href="/#">
                  Updated Soon
                </a>
              </li> */}
            </ul>
          </li>
          {/* Courses */}
          <li
            className="nav-item dropdown d-flex"
            style={{
              padding: "10px",
            }}
          >
            <a
              className="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style={{
                marginLeft: "5px",
              }}
            >
              Courses
            </a>
            <ul className="dropdown-menu">
              <li>
                <a className="dropdown-item" href="#">
                  Class - X
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Class - XII
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  {"B.A (Bachelor's Of Arts)"}
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  {"M.A (Master's Of Arts)"}
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Others
                </a>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  {"C.C.A (Certificate in Computer Application)"}
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  {"D.C.A (Diploma in Computer Application)"}
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  {"A.D.C.A (Advance Diploma in Computer Application)"}
                </a>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  B.ED/D.EL.ED
                </a>
              </li>
            </ul>
          </li>
          {/*  */}
          {/* Downloads */}
          <li
            className="nav-item dropdown d-flex"
            style={{
              padding: "10px",
            }}
          >
            <a
              className="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style={{
                marginLeft: "5px",
              }}
            >
              Downloads
            </a>
            <ul className="dropdown-menu">
              <li>
                <a
                  className="dropdown-item"
                  href="https://webservices.ignou.ac.in/assignments/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {"ASSIGNMENT (BA, MA)"}
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  href="https://www.nios.ac.in/student-information-section/tutor-mark-assignment-status.aspx"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {"ASSIGNMENT (X, XII)"}
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  href="https://ignouhall.ignou.ac.in/HallTickets/HALL0620/Hall0620.asp"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {"HALL TICKET"}
                </a>
              </li>
              <li>
                <hr className="dropdown-divider" />
                <span style={{ marginLeft: "5px" }}>BOSSE</span>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  href="https://kheci-api.vercel.app/bose/admit"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img
                    src="/newarrow.gif"
                    alt=""
                    width="20px"
                    height="20px"
                    style={{ marginRight: "5px" }}
                  />
                  {"BOSSE Admit"}
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  href="https://kheci-api.vercel.app/bose/marksheet"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img
                    src="/newarrow.gif"
                    alt=""
                    width="20px"
                    height="20px"
                    style={{ marginRight: "5px" }}
                  />
                  {"BOSSE Marksheet"}
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  href="https://kheci-api.vercel.app/bose/migration"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img
                    src="/newarrow.gif"
                    alt=""
                    width="20px"
                    height="20px"
                    style={{ marginRight: "5px" }}
                  />
                  {"BOSSE Migration"}
                </a>
              </li>
              <li>
                <hr className="dropdown-divider" />
                <span style={{ marginLeft: "5px" }}>B.Ed</span>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  href="https://kheci-api.vercel.app/bedDegree"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img
                    src="/newarrow.gif"
                    alt=""
                    width="20px"
                    height="20px"
                    style={{ marginRight: "5px" }}
                  />
                  {"B.Ed Degree"}
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  href="https://kheci-api.vercel.app/bedMarksheet"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img
                    src="/newarrow.gif"
                    alt=""
                    width="20px"
                    height="20px"
                    style={{ marginRight: "5px" }}
                  />
                  {"B.Ed Marksheet"}
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  href="https://kheci-api.vercel.app/bedCertificate"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img
                    src="/newarrow.gif"
                    alt=""
                    width="20px"
                    height="20px"
                    style={{ marginRight: "5px" }}
                  />
                  {"B.Ed Certificate"}
                </a>
              </li>
              <li>
                <hr className="dropdown-divider" />
                <span style={{ marginLeft: "5px" }}>D.El.Ed</span>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  href="https://kheci-api.vercel.app/deledRegistration"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img
                    src="/newarrow.gif"
                    alt=""
                    width="20px"
                    height="20px"
                    style={{ marginRight: "5px" }}
                  />
                  {"D.El.Ed Registration"}
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  href="https://kheci-api.vercel.app/deledCertificate"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img
                    src="/newarrow.gif"
                    alt=""
                    width="20px"
                    height="20px"
                    style={{ marginRight: "5px" }}
                  />
                  {"D.El.Ed Certificate"}
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  href="https://kheci-api.vercel.app/deledMarksheet"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img
                    src="/newarrow.gif"
                    alt=""
                    width="20px"
                    height="20px"
                    style={{ marginRight: "5px" }}
                  />
                  {"D.El.Ed Marksheet"}
                </a>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <a className="dropdown-item disabled" href="#">
                  Updated Soon
                </a>
              </li>
            </ul>
          </li>
          {/* Results */}
          <li
            className="nav-item dropdown d-flex"
            style={{
              padding: "10px",
            }}
          >
            <a
              className="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style={{
                marginLeft: "5px",
              }}
            >
              Results
            </a>
            <ul className="dropdown-menu">
              <li>
                <a
                  className="dropdown-item"
                  href="http://ignou.ac.in/ignou/studentzone/results/1"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {"RESULT (BA, MA)"}
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  href="https://results.nios.ac.in/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {"RESULT (X, XII)"}
                </a>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <a className="dropdown-item disabled" href="#">
                  Updated Soon
                </a>
              </li>
            </ul>
          </li>
          {/*  */}
          <li className="navListItem">
            <select
              className="navSelectLanguage"
              style={{
                outlineStyle: "none",
              }}
            >
              <option value={"english"}>English</option>
              <option value={"hindi"}>हिंदी</option>
              <option value={"Nepali"}>नेपाली</option>
            </select>
          </li>
        </ul>
      </div>
    </div>
  );
}
