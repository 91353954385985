import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { BrowserRouter, Route, RouterProvider, Routes } from "react-router-dom";
import { routerDetails } from "./routes/root.js";

import { Provider } from "react-redux";
import store from "../src/store.ts";
import ErrorPage from "./components/ErrorPage";
import VerifyCertificate from "./components/computer/VerifyCertificate";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  //   <App />
  //   <Provider store={store}>
  //     <RouterProvider router={routerDetails} />
  //   </Provider>
  // </React.StrictMode>

  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route Component={App} path="/" />
          <Route Component={VerifyCertificate} path="/computer/verify" />
          <Route path="*" Component={ErrorPage} />
        </Routes>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
