import "../../styles/components/someBlocksStyle/BlockComputerMain.scss";

export default function BlockComputerMain() {
  const sameStyle = {
    display: "block",
    maxWidth: "100%",
  };
  return (
    <div className="blockContainerComputerMain">
      <img
        src="https://kheci.org/updatedComputer.gif"
        alt="R-Tech Computer Institute"
        style={{
          display: "block",
          maxWidth: "100%",
        }}
      />
    </div>
  );
}
