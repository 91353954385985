export default function SomeCard({ title, icon, items, iconBanner }) {
  return (
    <div
      style={{
        width: "220px",
        height: "200px",
        // backgroundColor: "red",
        boxShadow: "#d6d6d6 0px 0px 10px 1px",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "20%",
          //   backgroundColor: "green",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#0a292d",
          color: "white",
        }}
      >
        {title}
      </div>
      {items?.map((item, index) => (
        <div
          key={index}
          style={{
            width: "100%",
            height: "15%",
            backgroundColor: "aliceblue",
            paddingLeft: "10px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <i className={item.header ? icon : ""}></i>
          <a
            href={item.link}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: "none",
              color: "#0b292d",
              fontSize: "14px",
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.paddingLeft = "10px";
              e.currentTarget.style.transition = "0.3s";
              e.currentTarget.style.textDecoration = "underline";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.paddingLeft = "0px";
              e.currentTarget.style.transition = "0.3s";
              e.currentTarget.style.textDecoration = "none";
            }}
          >
            {item.header}
          </a>
        </div>
      ))}
      {/*  */}
      <div
        style={{
          width: "100%",
          height: "20%",
          backgroundColor: "aliceblue",
          paddingRight: "10px",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <i
          className={iconBanner}
          style={{ fontSize: "30px", color: "gray" }}
        ></i>
      </div>
    </div>
  );
}
